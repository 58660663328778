<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { FunctionalComponent, ref } from 'vue'

import { SolidAnkor } from '@ankor-io/icons/solid'

export type NavLink = { name: string; href: string; icon?: FunctionalComponent; active?: boolean }
type Props = {
  navigation: NavLink[]
  apps: NavLink[]
  classNames?: { sidebar: string; content: string }
}
const props = defineProps<Props>()

const sidebarOpen = ref(false)
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80"></div>
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- left aside sidebar -->
              <slot name="leftAside">
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-600 px-6 pb-4">
                  <div class="flex h-16 shrink-0 items-center">
                    <a href="/"><SolidAnkor class="fill-white h-8 w-auto" /></a>
                  </div>
                  <slot name="nav">
                    <nav class="flex flex-1 flex-col">
                      <ul role="list" class="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" class="-mx-2 space-y-1">
                            <li v-for="item in props.navigation" :key="item.name">
                              <slot name="navItem" :item="item">
                                <RouterLink :to="item.href">
                                  <span
                                    :class="[
                                      item.active
                                        ? 'bg-primary-700 text-white'
                                        : 'transition-colors text-primary-200 hover:bg-primary-700 hover:text-white',
                                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                    ]"
                                  >
                                    <component
                                      :is="item.icon"
                                      :class="[
                                        item.active ? 'text-white' : 'text-primary-200 group-hover:text-white',
                                        'h-6 w-6 shrink-0',
                                      ]"
                                      aria-hidden="true"
                                    />
                                    {{ item.name }}
                                  </span>
                                </RouterLink>
                              </slot>
                            </li>
                          </ul>
                        </li>
                        <slot name="appGroup">
                          <li>
                            <slot name="appGroupTitle"></slot>
                            <ul role="list" class="-mx-2 space-y-1">
                              <li v-for="item in props.apps" :key="item.name">
                                <slot name="appGroupItem" :item="item">
                                  <a :href="item.href" target="_blank">
                                    <span
                                      class="transition-colors text-primary-200 hover:bg-primary-700 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    >
                                      <component
                                        :is="item.icon"
                                        class="text-primary-200 group-hover:text-white h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                      />
                                      {{ item.name }}
                                    </span>
                                  </a>
                                </slot>
                              </li>
                            </ul>
                          </li>
                        </slot>
                      </ul>
                    </nav>
                  </slot>
                </div>
              </slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- lg: left aside -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col" :class="[props.classNames?.sidebar]">
      <slot name="leftAside">
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-600 px-6 pb-4">
          <div class="flex h-16 shrink-0 items-center">
            <a href="/">
              <SolidAnkor class="fill-white h-8 w-auto" />
            </a>
          </div>
          <slot name="nav">
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li v-for="item in props.navigation" :key="item.name">
                      <slot name="navItem" :item="item">
                        <RouterLink :to="item.href">
                          <span
                            :class="[
                              item.active
                                ? 'bg-primary-700 text-white'
                                : 'transition-colors text-primary-200 hover:bg-primary-700 hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                item.active ? 'text-white' : 'text-primary-200 group-hover:text-white',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </span>
                        </RouterLink>
                      </slot>
                    </li>
                  </ul>
                </li>
                <slot name="appGroup">
                  <li>
                    <slot name="appGroupTitle"></slot>
                    <ul role="list" class="-mx-2 space-y-1">
                      <li v-for="item in props.apps" :key="item.name">
                        <slot name="appGroupItem" :item="item">
                          <a :href="item.href" target="_blank">
                            <span
                              class="transition-colors text-primary-200 hover:bg-primary-700 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            >
                              <component
                                :is="item.icon"
                                class="text-primary-200 group-hover:text-white h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </span>
                          </a>
                        </slot>
                      </li>
                    </ul>
                  </li>
                </slot>
              </ul>
            </nav>
          </slot>
        </div>
      </slot>
    </div>

    <div class="lg:pl-72 grid grid-rows-[auto_1fr] overflow-auto" :class="[props.classNames?.content || 'h-screen']">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>

        <slot name="header"></slot>
      </div>

      <main class="py-10 bg-gray-50 dark:bg-gray-800">
        <div class="px-4 sm:px-6 lg:px-8">
          <!-- Content -->
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>
