import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 36 33",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-width": "3",
      d: "M30.8741 17.9211L21.2259 11.3944C20.6893 11.0314 20.0102 10.9508 19.4036 11.1781L11.7057 14.0622C11.2397 14.2367 10.7168 14.0345 10.4896 13.5918V13.5918C10.293 13.2088 10.3693 12.7423 10.6777 12.4419L13.303 9.88457M30.8741 17.9211V17.9211C31.7152 18.49 31.3478 19.8022 30.3335 19.8516L28.2286 19.9541C27.1978 20.0043 26.4854 21.0056 26.7759 21.9959V21.9959C27.1441 23.2513 25.9272 24.3916 24.6983 23.9427L23.722 23.5861C22.8027 23.2503 21.8294 23.9309 21.8294 24.9096V24.9096C21.8294 26.0959 20.4532 26.7513 19.5322 26.0037L19.3184 25.8301C18.4465 25.1223 17.1348 25.5554 16.8557 26.643L16.8227 26.7714C16.4861 28.0832 14.7869 28.432 13.9607 27.3589L9.70284 21.8288C9.52279 21.595 9.29321 21.4039 9.03058 21.2692L3.65699 18.5142C2.95346 18.1535 2.52844 17.4123 2.57256 16.6229L2.86033 11.474C2.92109 10.3869 3.84021 9.54819 4.92827 9.58686L13.303 9.88457M30.8741 17.9211L32.7028 11.9834C33.0989 10.6972 32.1372 9.39474 30.7914 9.39474H29.0936C28.6407 9.39474 28.2013 9.24103 27.8471 8.95876L21.7495 4.09902C20.9615 3.47097 19.8293 3.52727 19.1075 4.23041L13.303 9.88457"
    })
  ]))
}